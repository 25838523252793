<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import type { LabelProps } from 'radix-vue';

const props = defineProps<LabelProps & { class?: HTMLAttributes['class']; required?: boolean }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <UILabel
    v-bind="delegatedProps"
    :class="
      cn(
        'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
        props.class,
      )
    "
  >
    <slot />
    <sup v-if="required" class="text-error">*</sup>
  </UILabel>
</template>
